import React, {useCallback, useEffect, useMemo, useState,} from 'react';
import { array, arrayOf, object, oneOf, string, boolean ,} from 'prop-types';
import { Accordion, SimpleLoading, SimpleSelect, } from '~/components';
import {ChevronDown} from 'react-feather';
import {
  useFetch,
  useDebounce,
} from '~/hooks';
import { useResultData } from '../../resultContext';
import RiskItem from '../RisksByTheme/RiskItem';
import { useTranslation } from 'react-i18next';
import { AlignDivs } from './styles'


const ItemRiskListEntry = ({
  showFilters,
  itemRiskType,
  columns,
  data,
  fetchUrl,
}) => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [courts, setCourts] = useState([]);
  const [years, setYears] = useState([]);
  const [polarities, setPolarities] = useState([]);
  const [selectedCourts, setSelectedCourts] = useState(null);
  const [selectedYears, setSelectedYears] = useState(null);
  const [selectedPolarities, setSelectedPolarities] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const { t } = useTranslation('diligence');
  const { addMutation } = useResultData();
  const [url, setUrl] = useState(fetchUrl);
  const { data: myData, isValidating, mutate } = useFetch({
    url: (shouldFetch && url) ? url : null,
  });
  const cortes = useDebounce(selectedCourts, 2000)
  const anos = useDebounce(selectedYears, 2000)
  const polos = useDebounce(selectedPolarities, 2000)

  useEffect(() => {
    if (url) addMutation(mutate);
  }, [addMutation, mutate, url]);

  const actualData = useMemo(() => {
    if (myData) return myData;
    if (data) return data;
    return [];
  }, [data, myData]);

  const onOpen = useCallback((isOpened) => {
    if (isOpened && ! shouldFetch && url) {
      setShouldFetch(true);
    }
  }, [shouldFetch, url]);

  const filterCourties = (<SimpleSelect
    isMulti
    icon={ChevronDown}
    value={selectedCourts}
    options={courts?.map((item) => ({
      value: item,
      label: item,
    }))}
    onChange={(val) => setSelectedCourts(val)}
    appearance="secondary"
    placeholder={t('filters.court') }
  />);

  const filterYears = (<SimpleSelect
    isMulti
    icon={ChevronDown}
    value={selectedYears}
    options={years?.map((item) => ({
      value: item,
      label: item,
    }))}
    onChange={(val) => setSelectedYears(val)}
    appearance="secondary"
    placeholder={t('filters.year') }
  />);

  const filterPolarities = (<SimpleSelect
    isMulti
    icon={ChevronDown}
    value={selectedPolarities}
    options={polarities?.map((item) => ({
      value: item,
      label: item,
    }))}
    onChange={(val) => setSelectedPolarities(val)}
    appearance="secondary"
    placeholder={t('filters.polarity')}
  />);

  useEffect(() => {
    let filter = actualData;
    if (showFilters) {
      const temp =
        fetchUrl +
        `?coluna1=${JSON.stringify(cortes)}` +
        `&coluna2=${JSON.stringify(anos)}` +
        `&coluna3=${JSON.stringify(polos)}`
        ;
      setUrl(temp);
    }
    setFilteredOptions(filter);
    
  }, [actualData, cortes, anos, polos])

  useEffect(() => {
    if (
      showFilters &&
      !selectedCourts &&
      !selectedYears &&
      !selectedPolarities
    ) {
      setCourts([... new Set(actualData.map((i) => {
        return i.coluna1;
      }))].sort((a, b) => {
        return a > b ? 1 : -1;
      }))
      setYears([... new Set(actualData.map((i) => {
        if (i.coluna2.includes('/') || i.coluna2.includes('-')) {
          return i.coluna2.substring(6,10);
        }
        return i.coluna2.substring(9, 13);
      }))].sort((a, b) => {
        return a < b ? 1 : -1;
      }))
      setPolarities([... new Set(actualData.map((i) => {
        return i.coluna3;
      }))].sort((a, b) => {
        return a > b ? 1 : -1;
      }))
    }
  }, [actualData, selectedCourts, selectedYears, selectedPolarities])

  return (
    <Accordion onChange={onOpen} columns={columns}>
      {isValidating ? <SimpleLoading /> : (
        <>
          {showFilters && (
            <AlignDivs>
              {filterCourties}
              {filterYears}
              {filterPolarities}
            </AlignDivs>
          )}
          {filteredOptions?.length > 0 && (
            filteredOptions?.map((riskItem) => {
              return <RiskItem
                isProcess={showFilters}
                key={riskItem.id}
                type={itemRiskType}
                data={riskItem}
                riskSourceID={riskItem.riskId || riskItem.risk_source_id}
                themeID={riskItem.themeId}
              />
            })
          )}
        </>
      )}
    </Accordion>
  );
};

ItemRiskListEntry.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: arrayOf(object),
  fetchUrl: string,
  itemRiskType: oneOf(['default', 'survey']),
  showFilters: boolean,
};

ItemRiskListEntry.defaultProps = {
  data: [],
  fetchUrl: null,
  itemRiskType: 'default',
  showFilters: false,
};

export default ItemRiskListEntry;
